<template>
  <transition appear name="fade">
    <div class="login-body pt-4">
      <form v-on:submit.prevent="">
        <transition appear mode="out-in" name="fade">
          <div key="regular-login" class="login-card card align-middle p-4 mx-auto">
            <div>
              <h2>Card creator</h2>
              <strong class="mb-2 d-block">Login</strong>
            </div>
            <div class="form-label-group py-1">
              <input id="inputUsername" v-model="username" autofocus class="form-control" placeholder="Username" required type="text">
            </div>
            <div class="form-label-group py-1">
              <input id="inputPassword" v-model="password" class="form-control" placeholder="Password" required type="password">
            </div>
            <button v-on:click='authenticate(username, password)' class="btn btn-primary d-inline-block w-50 btn-lg mt-2">Sign in</button>
          </div>
        </transition>
      </form>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'login',
  data () {
    return {
      username: '',
      password: '',
    }
  },
  created () { },
  methods: {
    authenticate: function (username, password) {
      this.$store
          .dispatch('auth/logIn', {
            username,
            password
          })
          .then(() => {
                this.$router.push({ name: 'AdminCardsCreate1' })
              },
              (err) => {
                if (err.response.status === 403) {
                  this.showError('Incorrect user name and/or password')
                } else {
                  this.showError('Unknown error')
                }
              })
          .catch(() => {
            this.showError('Unknown error')
          })
    },
    showError: function (message) {
      this.$bvModal.msgBoxOk(message, {
        size: 'sm',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    }
  }
}
</script>

<style lang="scss">
.login-card{
  max-width:450px;
}
</style>
