var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c("div", { staticClass: "login-body pt-4" }, [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "transition",
            { attrs: { appear: "", mode: "out-in", name: "fade" } },
            [
              _c(
                "div",
                {
                  key: "regular-login",
                  staticClass: "login-card card align-middle p-4 mx-auto"
                },
                [
                  _c("div", [
                    _c("h2", [_vm._v("Card creator")]),
                    _c("strong", { staticClass: "mb-2 d-block" }, [
                      _vm._v("Login")
                    ])
                  ]),
                  _c("div", { staticClass: "form-label-group py-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.username,
                          expression: "username"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputUsername",
                        autofocus: "",
                        placeholder: "Username",
                        required: "",
                        type: "text"
                      },
                      domProps: { value: _vm.username },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.username = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "form-label-group py-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputPassword",
                        placeholder: "Password",
                        required: "",
                        type: "password"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-primary d-inline-block w-50 btn-lg mt-2",
                      on: {
                        click: function($event) {
                          return _vm.authenticate(_vm.username, _vm.password)
                        }
                      }
                    },
                    [_vm._v("Sign in")]
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }